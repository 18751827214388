<template>
    <div class="contain">
        <div class="newsgg navRcon">
            <div class="newstit clear">
                <div class="fl fontsize20">中标公示</div>
                <div class="fr more colorf3c fontsize16">
                    <router-link to="/notice?key=WinTheBid">查看更多+</router-link>
                </div>
            </div>
            <ul class="culList">
                <router-link
                    :to="
                        '/notice-details?type=WinTheBid&id=' +
                            item.winningId +
                            '&index=' +
                            (index - 0 + 1)
                    "
                    v-for="(item, index) in dataList"
                    :key="index"
                >
                    <li class="clear">
                        <div class="culList_tit textSl1 fontsize16 color333">
                            {{
                                item.title +
                                    (item.itemNo
                                        ? "(项目编号" + item.itemNo + ")"
                                        : "")
                            }}
                        </div>
                        <div class="newlist_con_timg fr">
                            <!-- <img src="../../assets/images/index/line1.png" /> -->
                        </div>
                        <div class="culList_time fontsize16 color666 fr">
                            {{ onConversion(item.releaseTime) }}
                        </div>
                    </li>
                </router-link>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataList: [],
            total: 1, // 总数
            pageNo: 1, // 当前页
            pageSize: 5 // 每页显示条数
        };
    },
    mounted() {
        this.getinfoTwList();
    },
    methods: {
        // 时间转换
        onConversion(date) {
            // console.log(date);
            var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
            // let nDate = new Date(date),
            var y = nDate.getFullYear(),
                m = ("0" + (nDate.getMonth() + 1)).slice(-2),
                d = ("0" + nDate.getDate()).slice(-2);
            // console.log(date)
            return y + "-" + m + "-" + d;
        },
        async getinfoTwList() {
            let res = await this.$request.getinfoTwList({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            });
            this.dataList = res.rows;
            this.total = res.total;
            // console.log(res);
        }
    }
};
</script>
<style lang="scss" scoped>
// @import "../../assets/css/index.css";

/*招标公告*/
.newsgg {
    width: 47.1354vw;
    height: auto;
    padding: 0 1.0417vw;
    box-sizing: border-box;
    border: solid 0.0521vw #e0e0e0;
}

.newsgg li {
    width: 100%;
    height: 3.125vw;
    line-height: 3.125vw;
    position: relative;
    padding: 0 1.0417vw;
    border: 0.0521vw solid #eeeeee;
    box-sizing: border-box;
    margin-bottom: 0.52vw;
}
.newlist_con_timg {
    display: inline-block;
    height: 100%;
    background: url('../../assets/images/index/line1.png') no-repeat center center;
    background-size: 100%;
}
.newsgg li::before {
    position: absolute;
    content: "";
    display: block;
    width: 0.2083vw;
    height: 2.6042vw;
    left: -0.1042vw;
    top: 0.2604vw;
    background-color: #f3ca00;
}
.newsgg li:hover {
    background: #f3ca00;
    .culList_tit {
        color: #fff !important;
    }
    .culList_time {
        color: #fff !important;
    }
    .newlist_con_timg {
        background: url('../../assets/images/index/line2.png') no-repeat center center;
        background-size: 100%;
    }
    &::before {
        background: none;
    }
}
.newsgg .newstit {
    width: 100%;
    height: 2.6042vw;
    line-height: 2.6042vw;
}

.newsgg .culList_tit {
    width: 60%;
    float: left;
    min-height: 20px;
}

.newsgg .newlist_con_timg {
    width: 1.8229vw;
    margin-left: 1.5625vw;
}

.newsgg .newlist_con_timg img {
    width: 100%;
    position: relative;
    top: -0.1563vw;
    display: inline-block;
}
@media (min-width: 1920px) {
    .newsgg li {
        width: 100%;
        height: 60px;
        line-height: 60px;
        position: relative;
        padding: 0 20px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    .newlist_con_timg {
        display: inline-block;
        height: 100%;
        background: url('../../assets/images/index/line1.png') no-repeat center center;
        background-size: 100%;
    }
    .newsgg li::before {
        position: absolute;
        content: "";
        display: block;
        width: 4px;
        height: 50px;
        left: -2px;
        top: 5px;
        background-color: #f3ca00;
    }
    .newsgg li:hover {
        background: #f3ca00;
        .culList_tit {
            color: #fff !important;
        }
        .culList_time {
            color: #fff !important;
        }
        .newlist_con_timg {
            background: url('../../assets/images/index/line2.png') no-repeat center
                center;
            background-size: 100%;
        }
        &::before {
            background: none;
        }
    }
    
    /*招标公告*/
    .newsgg {
        width: 905px;
        height: auto;
        padding: 0 20px;
        box-sizing: border-box;
        border: solid 1px #e0e0e0;
    }
    .newsgg .newstit {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }

    .newsgg .culList_tit {
        width: 60%;
        float: left;
    }

    .newsgg .newlist_con_timg {
        width: 35px;
        margin-left: 30px;
    }

    .newsgg .newlist_con_timg img {
        width: 100%;
        position: relative;
        top: -3px;
        display: inline-block;
    }
}
</style>
