import { render, staticRenderFns } from "./gknews.vue?vue&type=template&id=11193250&scoped=true"
import script from "./gknews.vue?vue&type=script&lang=js"
export * from "./gknews.vue?vue&type=script&lang=js"
import style0 from "./gknews.vue?vue&type=style&index=0&id=11193250&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11193250",
  null
  
)

export default component.exports