<template>
  <div class="contain">
    <!-- 企业新闻 -->
    <div class="conbg navRcon">
      <div class="newstit clear">
        <div class="fl fontsize20">企业新闻</div>
        <div class="fr more colorf3c fontsize16 cusorS">
          <router-link to="/news">查看更多+</router-link>
        </div>
      </div>
      <ul class="newslist">
        <div
          class="nlink"
          @click="
              splicingUrl({
                  base: '/news-details?id=' + item.infoId,
                  index:
                  (index - 0 + 1),
                  type: 'company'
              },item.externallinks)
          "
          v-for="(item, index) in impoptantNewsList"
          :key="index"
        >
          <li class="clear">
            <div class="logo">
              <img :src="$store.state.baseUrl + item.infoImage" />
            </div>
            <div class="newlist_con">
              <div class="newlist_con_tit fontsize16">
                {{ item.infoName }}
              </div>
              <!-- <div class="marginT10 textSl1 fontsize16 color666">
                                {{ item.infoContent }}
                            </div> -->
              <div class="clear marginT10 newlist_con_time fontsize16 color666">
                <span class="fl">{{ onConversion(item.publishTime) }}</span>
                <div class="newlist_con_timg fr cusorS">
                  <!-- <img src="../assets/images/index/line1.png" /> -->
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      impoptantNewsList: [],
      impoptantTotal: 1, // 总数
      impoptantPageNo: 1, // 当前页
      impoptantPageSize: 3, // 每页显示条数
    };
  },
  mounted() {
    this.getInfoManageList();
  },
  methods: {
     // url拼接
     splicingUrl({ base, index, type },externallinks) {
            console.log(externallinks)
            if(externallinks){
                window.open(externallinks)
            }else{
                this.$router.push({ path: base + "&index=" + index + "&type=" + type });
            }
            // return base + "&index=" + index + "&type=" + type;
        },
    async getInfoManageList() {
      let res = await this.$request.getInfoManageList({
        pageNo: this.impoptantPageNo,
        pageSize: this.impoptantPageSize,
        infoColumn: "0",
      });
      this.impoptantNewsList = res.rows;
      this.impoptantTotal = res.total;
      // console.log(this.impoptantNewsList);
    },
    // 时间转换
    onConversion(date) {
      // console.log(date);
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
  },
};
</script>
<style lang="scss" scoped>
.conbg {
  border: solid 0.0521vw #e0e0e0;
  width: 100%;

  .newstit {
    height: 2.6042vw;
    line-height: 2.6042vw;
    border-bottom: 0.0521vw solid #eeeeee;
    margin-left: 0.7813vw;
    margin-right: 0.7813vw;
  }
}
.newslist {
  .nlink {
    display: block;
    cursor: pointer;
    position: relative;

    li {
      display: block;
      padding: 0.7813vw;
      border-bottom: 0.0521vw solid #eeeeee;

      &:hover {
        background: #f3ca00;
        .newlist_con_tit {
          color: #fff !important;
        }
        .newlist_con_time {
          color: #fff !important;
        }
        .newlist_con_timg {
          background: url("../../assets/images/index/line2.png") no-repeat
            center center;
          background-size: 100%;
        }
        &::before {
          background: none;
        }
      }
      .newlist_con_timg {
        width: 1.82vw;
        height: 0.31vw;
        display: inline-block;
        background: url("../../assets/images/index/line1.png") no-repeat center
          center;
        background-size: 100%;
      }

      .logo {
        width: 6.5625vw;
        height: 4.2188vw;
        float: left;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .newlist_con {
        float: left;
        width: 38.9063vw;
        box-sizing: border-box;
        padding-left: 1.0417vw;
        margin-top: 0.7813vw;

        .newlist_con_tit {
          color: #333333;
          font-weight: 700;
        }
      }
    }
  }
}
@media (min-width: 1920px) {
  .conbg {
    border: solid 1px #e0e0e0;
    width: 100%;

    .newstit {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #eeeeee;
      margin-left: 15px;
      margin-right: 15px;
    }

    .newslist {
      .nlink {
        display: block;
        cursor: pointer;
        position: relative;

        li {
          display: block;
          padding: 15px;
          border-bottom: 1px solid #eeeeee;

          &::hover {
            background: #f3ca00;
            .newlist_con_tit {
              color: #fff !important;
            }
            .newlist_con_time {
              color: #fff !important;
            }
            .newlist_con_timg {
              background: url("../../assets/images/index/line2.png") no-repeat
                center center;
              background-size: 100%;
            }
            &::before {
              background: none;
            }
          }
          .newlist_con_timg {
            width: 35px;
            display: inline-block;
            height: 6px;
            background: url("../../assets/images/index/line1.png") no-repeat
              center center;
            background-size: 100%;
          }
          .logo {
            width: 126px;
            height: 81px;
            float: left;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .newlist_con {
            float: left;
            width: 747px;
            box-sizing: border-box;
            padding-left: 20px;
            margin-top: 15px;

            .newlist_con_tit {
              color: #333333;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>
