var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain clear"},[_c('div',{staticClass:"hconbg clear navRcon"},[_c('div',{staticClass:"fl honor_nav"},[_c('div',{class:[
          _vm.honorbtn == 'honor' ? 'honorOn' : '',
          'honorbtn marginT20 textC cusorS fontsize16',
        ],on:{"click":function($event){_vm.honorbtn = 'honor'}}},[_vm._v(" 荣誉> ")]),_c('div',{class:[
          _vm.honorbtn == 'Qualification' ? 'honorOn' : '',
          'honorbtn marginT10 textC cusorS fontsize16',
        ],on:{"click":function($event){_vm.honorbtn = 'Qualification'}}},[_vm._v(" 资质> ")])]),_c('div',{staticClass:"fl honor_r"},[_c('div',{class:[
          _vm.honorbtn == 'honor' ? 'on' : '',
          'honor_r_con honor_r_con01 ',
        ]},[_c('div',{staticClass:"textp fontsize16 color333"},[_c('span',[_vm._v("荣誉奖项：")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.honorInfo)}},[_vm._v(" 我司机电运维事业部连续多次获评全国设备管理优秀单位、广交会优秀外包单位；物业事业部在管的广州铜材厂有限公司被广东省建设厅评为“广东省物业管理示范工业区、广州市物业管理优秀工业区”。 ")])]),(_vm.honorImgList.length > 0)?_c('swiperCpt',{ref:"honorswiper",staticClass:"swiperhor",attrs:{"slidesPerView":2,"autoplay":true,"loop":true,"sid":"honorswiper"}},_vm._l((_vm.honorImgList),function(item,index){return _c('div',{key:'honor' + index,staticClass:"swiper-slide"},[_c('ul',{staticClass:"honor_img clear marginT20"},[_c('li',[_c('img',{attrs:{"src":_vm.$store.state.baseUrl + item.imageUrl,"alt":'honor' + index}})])])])}),0):_vm._e()],1),_c('div',{class:[
          _vm.honorbtn == 'Qualification' ? 'on' : '',
          'honor_r_con honor_r_con01 ',
        ]},[_c('div',{staticClass:"textp fontsize16 color333"},[_c('span',[_vm._v("公司资质：")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.QualificationInfo)}},[_vm._v(" 拥有设备维修安装、机电工程施工、电梯安装维修、物业服务等多项资质，并通过了ISO9001国际质量管理体系、ISO14001国际环境管理体系、OHSAS18001职业健康安全管理体系等认证。 ")])]),(_vm.QualificationImgList.length > 0)?_c('swiperCpt',{ref:"QualificationSwiper",staticClass:"swiperhor",attrs:{"slidesPerView":4,"loop":true,"delay":3000,"autoplay":true,"sid":"QualificationSwiper"}},_vm._l((_vm.QualificationImgList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('ul',{staticClass:"honor_img1 clear marginT20"},[_c('li',[_c('img',{attrs:{"src":_vm.$store.state.baseUrl + item.imageUrl,"alt":'Qualification' + index}})])])])}),0):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }