<template>
  <div class="container">
    <swiperCpt
      class="wow fadeInUp animated"
      ref="indexBannerSwiper"
      :delay="5000"
      :loop="true"
      :autoplay="true"
      @onSlideChangeEnd="onSlideChangeEnd"
      sid="indexBannerSwiper"
      v-if="isShow"
    >
      <div
        class="swiper-slide"
        v-for="(item, index) in resBanners"
        :key="index"
      >
        <div
          class="banner"
          :class="[
            activeIndex == index ? 'fadeInUp animationDelay animated' : '',
          ]"
        >
          <img :src="item.src" ref="imgH" />
          <div
            class="txtsmbg"
            :class="[
              activeIndex == index ? 'fadeInUp1 animationDelay animated' : '',
              item.retainfield1,
            ]"
          >
            <div class="img"></div>
          </div>
        </div>
      </div>
    </swiperCpt>
    <!-- 工控新闻 -->
    <gknew class="wow fadeInUp animated" />
    <!-- 业务形态 -->
    <gkform class="wow fadeInUp animated" />
    <!-- 业务形态 -->
  </div>
</template>

<script>
import $ from "jquery.1";
import swiperCpt from "@/components/common/swiperCpt.vue";
import gkform from "@/components/index/gkform.vue";
import gknew from "@/components/index/gknews.vue";
import Swiper from "swiper/dist/idangerous.swiper.min.js";
import "swiper/dist/idangerous.swiper.css";
if (process.browser) {
  var { WOW } = require("wowjs");
}
export default {
  components: { gknew, gkform, swiperCpt },
  data() {
    let _that = this;
    return {
      isShow: false,
      activeIndex: 0,
      resBanners: [],
      // imgHeight: "0",
    };
  },
  computed: {
    test() {
      // console.log(this.activeIndex)
      return this.activeIndex;
    },
  },
  created() {
    this.getDataList();
  },
  mounted() {
    // this.imgLoad();

    if (process.browser) {
      new WOW({
        live: false,
        offset: 0,
      }).init();
    }
    // window.addEventListener("resize", this.imgLoad, false);
  },
  methods: {
    onSlideChangeEnd(swiper) {
      $(".swiper-slide")
        .find(".banner")
        .removeClass("fadeInUp animationDelay animated");
      $(".swiper-slide")
        .find(".txtsmbg")
        .removeClass("fadeInUp1 animationDelay animated")
        .css("opacity", "0");
      $(".swiper-slide-active")
        .find(".banner")
        .addClass("fadeInUp animationDelay animated");
      $(".swiper-slide-active")
        .find(".txtsmbg")
        .addClass("fadeInUp1 animationDelay animated");
      // console.log(swiper.activeLoopIndex, '轮播图', this.activeIndex)
    },

    // imgLoad() {
    //   this.$nextTick(() => {
    //     this.imgHeight = `${this.$refs.imgH[0].height}px`;
    //   });
    // },
    async getDataList() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "0",
      });
      if (res.code === 200) {
        let resBanners = [];
        res.rows.map((item, index) => {
          resBanners.push({
            src: this.$store.state.baseUrl + item.imageUrl,
            link: item.skipHref,
            retainfield1: item.retainfield1,
          });
        });
        this.resBanners = resBanners;
        this.$nextTick(() => {
          this.isShow = true;
        });
      }
    },
  },
  // destroyed() {
  //   // window.removeEventListener("resize", this.imgLoad, false);
  // },
};
</script>

<style lang="scss" scoped>
// @import "~/assets/css/index.css";
.conbg {
  border: solid 0.0521vw #e0e0e0;
  width: 100%;
}

.conbg .newstit {
  height: 2.6042vw;
  line-height: 2.6042vw;
  border-bottom: 0.0521vw solid #eeeeee;
  margin-left: 0.7813vw;
  margin-right: 0.7813vw;
}

.conbg .newslist li {
  padding: 0.7813vw;
  position: relative;
}

.conbg .newslist li:hover {
  background: #eeeeee;
}

.conbg .newslist li .logo {
  width: 6.5625vw;
  height: 4.2188vw;
  float: left;
  overflow: hidden;
}

.conbg .newslist li .logo img {
  width: 100%;
}

.conbg .newslist li .newlist_con {
  float: left;
  width: 38.9063vw;
  box-sizing: border-box;
  padding-left: 1.0417vw;
  margin-top: 0.7813vw;
}

.conbg .newslist li .newlist_con .newlist_con_tit {
  color: #333333;
  font-weight: 700;
}

.newleft {
  width: 32.8125vw;
  margin-left: 1.5625vw;
  margin-top: 2.0833vw;
}

.newleft .newimg {
  width: 7.7083vw;
  display: inline-block;
  position: relative;
  color: #fff;
  margin-right: 0.2604vw;
}

.newleft .newimg:last-child {
  margin-right: 0;
}

.newleft .newimg .newimg_bg {
  transition: 0.3s;
}

.newleft .newimg img {
  width: 100%;
  vertical-align: middle;
}

.newleft .newimg .newimg_con {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.newleft .newimg .newimg_con.navOn {
  top: 2.5vw;
}

.newleft .newimg .newimg_con .newimg_con_ico {
  width: 2.1354vw;
  height: 2.1354vw;
  margin: 1.5625vw auto;
}

.newleft .newimg .newimg_con .newimg_con_ico img {
  width: 100%;
}

.newleft .newimg .newimg_con .newimg_con_tit {
  margin-top: 1.0417vw;
  position: relative;
}

.newleft .newimg .newimg_con .newimg_con_tit::after {
  content: "";
  display: block;
  position: absolute;
  width: 1.3021vw;
  height: 0.1042vw;
  background: #fff;
  bottom: -0.2604vw;
  left: 50%;
  margin-left: -0.651vw;
}

.newleft .newimg .newimg_con .newimg_con_ytit {
  margin-top: 1.0417vw;
  line-height: 1.0417vw;
}

.newleft .newimg .newimg_con .newimg_con_ico3 {
  width: 1.8229vw;
}

.newscon {
  width: 47.1354vw;
}

.swiper-slide-active .txtcon {
  opacity: 1;
}

.culList li {
  margin-bottom: 1.0417vw;
}

.culList li:hover {
  background: #eeeeee;
}

.newslist .newlist_con .newlist_con_timg {
  width: 1.9792vw;
  position: absolute;
  right: 0.7813vw;
  top: 2.2396vw;
}

.newslist .newlist_con .newlist_con_timg img {
  width: 100%;
}

/*业务形态*/
.formTit {
  margin-top: 2.0833vw;
  text-align: center;
}

.formTit_en {
  color: #333333;
  opacity: 0.3;
  font-size: 1.5625vw;
}

.formTit_cn {
  font-size: 1.25vw;
  color: #333333;
  margin-top: -1.4583vw;
  font-weight: 700;
}

.swiper-container {
  width: 100%;
}

.border_box {
  width: 100%;
}

.box_skitter_large {
  width: 100% !important;
  height: auto !important;
}

.fontsize15 {
  font-size: 0.7813vw !important;
}

.fontsize16 {
  font-size: 0.8333vw !important;
}

.fontsize18 {
  font-size: 0.9375vw !important;
}

.fontsize20 {
  font-size: 1.0417vw !important;
}

.fontsize24 {
  font-size: 1.25vw !important;
}

.fontsize36 {
  font-size: 1.875vw !important;
}

.txtsmbg {
  display: block;
  width: 83.33vw;
  left: 50%;
  margin-left: -41.67vw;
  text-align: center;
  position: absolute;
}

.txtsmbg .img {
  display: inline-block;
  width: 100%;
}

.txtsmbg0 {
  top: 6.77vw;
}

.txtsmbg0 .img {
  width: 20.83vw;
  height: 8.85vw;
  background: url("../assets/images/index/banner1bg.png") no-repeat;
  background-size: contain;
}

.txtsmbg1 {
  top: 13.02vw;
  right: 0;
  text-align: right;
}

.txtsmbg1 .img {
  width: 19.27vw;
  height: 8.85vw;
  background: url("../assets/images/index/banner2bg.png") no-repeat;
  background-size: contain;
}

.txtsmbg2 {
  top: 13.02vw;
  right: 0;
  text-align: right;
}

.txtsmbg2 .img {
  width: 29.69vw;
  height: 8.85vw;
  background: url("../assets/images/index/banner3bg.png") no-repeat;
  background-size: contain;
}

@media (min-width: 1920px) {
  .conbg {
    border: solid 1px #e0e0e0;
    width: 100%;
  }

  .conbg .newstit {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eeeeee;
    margin-left: 15px;
    margin-right: 15px;
  }

  .conbg .newslist li {
    padding: 15px;
    position: relative;
  }

  .conbg .newslist li:hover {
    background: #eeeeee;
  }

  .conbg .newslist li .logo {
    width: 126px;
    height: 81px;
    float: left;
    overflow: hidden;
  }

  .conbg .newslist li .logo img {
    width: 100%;
  }

  .conbg .newslist li .newlist_con {
    float: left;
    width: 747px;
    box-sizing: border-box;
    padding-left: 20px;
    margin-top: 15px;
  }

  .conbg .newslist li .newlist_con .newlist_con_tit {
    color: #333333;
    font-weight: 700;
  }

  .newleft {
    width: 630px;
    margin-left: 30px;
    margin-top: 40px;
  }

  .newleft .newimg {
    width: 148px;
    display: inline-block;
    position: relative;
    color: #fff;
    margin-right: 5px;
    transition: 0.6s;
  }

  .newleft .newimg:last-child {
    margin-right: 0;
  }

  .newleft .newimg .newimg_bg {
    transition: 0.3s;
  }

  .newleft .newimg img {
    width: 100%;
    vertical-align: middle;
  }

  .newleft .newimg .newimg_con {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .newleft .newimg .newimg_con.navOn {
    top: 48px;
  }

  .newleft .newimg .newimg_con .newimg_con_ico {
    width: 41px;
    height: 41px;
    margin: 30px auto;
  }

  .newleft .newimg .newimg_con .newimg_con_ico img {
    width: 100%;
  }

  .newleft .newimg .newimg_con .newimg_con_tit {
    margin-top: 20px;
    position: relative;
  }

  .newleft .newimg .newimg_con .newimg_con_tit::after {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 2px;
    background: #fff;
    bottom: -5px;
    left: 50%;
    margin-left: -12.5px;
  }

  .newleft .newimg .newimg_con .newimg_con_ytit {
    margin-top: 20px;
    line-height: 20px;
  }

  .newleft .newimg .newimg_con .newimg_con_ico3 {
    width: 35px;
  }

  .newscon {
    width: 905px;
  }

  .culList li {
    margin-bottom: 20px;
  }

  .culList li:hover {
    background: #eeeeee;
  }

  .newslist .newlist_con .newlist_con_timg {
    width: 38px;
    position: absolute;
    right: 15px;
    top: 43px;
  }

  .newslist .newlist_con .newlist_con_timg img {
    width: 100%;
  }

  /*业务形态*/
  .formTit {
    margin-top: 40px;
    text-align: center;
  }

  .formTit_en {
    color: #333333;
    opacity: 0.3;
    font-size: 30px;
  }

  .formTit_cn {
    font-size: 24px;
    color: #333333;
    margin-top: -22px;
    font-weight: 700;
  }

  .swiper-container {
    width: 100%;
  }

  .border_box {
    width: 100%;
  }

  .box_skitter_large {
    width: 100% !important;
    height: auto !important;
  }

  .txtsmbg {
    display: block;
    width: 1600px;
    left: 50%;
    margin-left: -800px;
    text-align: center;
    position: absolute;
  }

  .txtsmbg .img {
    display: inline-block;
    width: 100%;
  }

  .txtsmbg0 {
    top: 130px;
  }

  .txtsmbg0 .img {
    width: 400px;
    height: 170px;
    background: url("../assets/images/index/banner1bg.png") no-repeat;
    background-size: contain;
  }

  .txtsmbg1 {
    top: 250px;
    right: 0;
    text-align: right;
  }

  .txtsmbg1 .img {
    width: 370px;
    height: 170px;
    background: url("../assets/images/index/banner2bg.png") no-repeat;
    background-size: contain;
  }

  .txtsmbg2 {
    top: 250px;
    right: 0;
    text-align: right;
  }

  .txtsmbg2 .img {
    width: 570px;
    height: 170px;
    background: url("../assets/images/index/banner3bg.png") no-repeat;
    background-size: contain;
  }
}

.animationDelay {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
</style>