<template>
    <div class="mainBody clear marginT40">
        <div class="fl newleft">
            <div
                :class="[index == nindex ? 'on' : '', 'newimg cusorS']"
                v-for="(item, index) in newNav"
                @click="swithRouter(index)"
                :key="index"
            >
                <b :class="['newimg_bg', 'newimg_bg' + (index - 0 + 1)]"></b>
                <div
                    class="newimg_con textC "
                    :class="{ navOn: index == nindex }"
                >
                    <div class="newimg_con_ico ">
                        <img :src="item.icon" />
                    </div>
                    <div class="newimg_con_tit fontsize18">
                        {{ item.cntit }}
                    </div>
                    <div class="newimg_con_ytit fontsize14">
                        {{ item.entit }}<br />
                        {{ item.fentit }}
                    </div>
                </div>
            </div>
        </div>
        <div class="fr newscon">
            <keep-alive>
                <component :is="routerArr[nindex]"></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
import newscon from "@/components/index/newscon.vue";
import honor from "@/components/index/honor.vue";
import culture from "@/components/index/culture.vue";
import notice from "@/components/index/notice.vue";
export default {
    components: { newscon, honor, culture, notice },
    data() {
        return {
            nindex: 1,
            newNav: [
                {
                    cntit: "企业新闻",
                    entit: "ENTERPRISE",
                    fentit: " NEWS",
                    newimg_bg: require("../../assets/images/index/tab1.png"),
                    newimg_bgon: require("../../assets/images/index/tab1on.png"),
                    icon: require("../../assets/images/index/icon1.png")
                },
                {
                    cntit: "荣誉资质",
                    entit: "HONOR AND ",
                    fentit: "QUALIFICATION",
                    newimg_bg: require("../../assets/images/index/tab2.png"),
                    newimg_bgon: require("../../assets/images/index/tab2on.png"),
                    icon: require("../../assets/images/index/icon2.png")
                },
                {
                    cntit: "招标公告",
                    entit: "BIDDING ",
                    fentit: "NOTICE",
                    newimg_bg: require("../../assets/images/index/tab3.png"),
                    newimg_bgon: require("../../assets/images/index/tab3on.png"),
                    icon: require("../../assets/images/index/icon3.png")
                },
                {
                    cntit: "中标公示",
                    entit: "BID WINNER",
                    fentit: " LIST",
                    newimg_bg: require("../../assets/images/index/tab4.png"),
                    newimg_bgon: require("../../assets/images/index/tab4on.png"),
                    icon: require("../../assets/images/index/icon4.png")
                }
            ],
            routerArr: ["newscon", "honor", "culture", "notice"]
        };
    },
    methods: {
        swithRouter(index) {
            this.nindex = index;
        }
    }
};
</script>
<style lang="scss" scoped>
.newleft {
    width: 32.81vw;
    margin-left: 1.56vw;
    margin-top: 2.08vw;
    .newimg {
        width: 7.71vw;
        height: 15.63vw;
        display: inline-block;
        position: relative;
        color: #fff;
        margin-right: 0.26vw;
        transition: 0.6s;
        &:last-child {
            margin-right: 0;
        }
        &.on {
            .newimg_bg {
                height: 15.63vw;
                margin: -7.81vw 0 0 0;
            }
        }
        .newimg_bg {
            display: block;
            width: 100%;
            height: 10.42vw;
            position: absolute;
            top: 50%;
            left: 0;
            margin: -5.21vw 0 0 0;
            border-radius: 0.26vw;
            transition: 0.3s ease-in-out;
            &.newimg_bg1 {
                background: url("../../assets/images/index/tab1on.png") 0 0
                    no-repeat;
                    background-size: cover;
            }
            &.newimg_bg2 {
                background: url("../../assets/images/index/tab2on.png") 0 0
                    no-repeat;
                    background-size: cover;
            }
            &.newimg_bg3 {
                background: url("../../assets/images/index/tab3on.png") 0 0
                    no-repeat;
                    background-size: cover;
            }
            &.newimg_bg4 {
                background: url("../../assets/images/index/tab4on.png") 0 0
                    no-repeat;
                    background-size: cover;
            }
        }
        .newimg_con {
            position: absolute;
            top: 2.5vw;
            bottom: 0;
            left: 0;
            right: 0;
            transition: 0.3s;
            .newimg_con_ico {
                width: 2.14vw;
                height: 2.14vw;
                margin: 1.56vw auto;
                img {
                    width: 100%;
                }
            }
            .newimg_con_tit {
                margin-top: 1.04vw;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 1.3vw;
                    height: 0.1vw;
                    background: #fff;
                    bottom: -0.26vw;
                    left: 50%;
                    margin-left: -0.65vw;
                }
            }
            .newimg_con_ytit {
                margin-top: 1.04vw;
                line-height: 1.04vw;
            }
        }
        .newimg_con_ico3 {
            width: 1.82vw;
        }
    }
}

.newscon {
    width: 47.14vw;
}

@media (min-width: 1920px) {
    .newleft {
        width: 630px;
        margin-left: 30px;
        margin-top: 40px;
        .newimg {
            width: 148px;
            height: 300px;
            display: inline-block;
            position: relative;
            color: #fff;
            margin-right: 5px;
            transition: 0.6s;
            &:last-child {
                margin-right: 0;
            }
            &.on {
                .newimg_bg {
                    height: 300px;
                    margin: -150px 0 0 0;
                }
            }
            .newimg_bg {
                display: block;
                width: 100%;
                height: 200px;
                position: absolute;
                top: 50%;
                left: 0;
                margin: -100px 0 0 0;
                border-radius: 5px;
                transition: 0.3s ease-in-out;
                &.newimg_bg1 {
                    background: url("../../assets/images/index/tab1on.png") 0 0
                        no-repeat;
                        background-size: cover;
                }
                &.newimg_bg2 {
                    background: url("../../assets/images/index/tab2on.png") 0 0
                        no-repeat;
                        background-size: cover;
                }
                &.newimg_bg3 {
                    background: url("../../assets/images/index/tab3on.png") 0 0
                        no-repeat;
                        background-size: cover;
                }
                &.newimg_bg4 {
                    background: url("../../assets/images/index/tab4on.png") 0 0
                        no-repeat;
                        background-size: cover;
                }
            }
            .newimg_con {
                position: absolute;
                top: 48px;
                bottom: 0;
                left: 0;
                right: 0;
                transition: 0.3s;
                .newimg_con_ico {
                    width: 41px;
                    height: 41px;
                    margin: 30px auto;
                    img {
                        width: 100%;
                    }
                }
                .newimg_con_tit {
                    margin-top: 20px;
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 25px;
                        height: 2px;
                        background: #fff;
                        bottom: -5px;
                        left: 50%;
                        margin-left: -12.5px;
                    }
                }
                .newimg_con_ytit {
                    margin-top: 20px;
                    line-height: 20px;
                }
            }
            .newimg_con_ico3 {
                width: 35px;
            }
        }
    }

    .newscon {
        width: 905px;
    }
}
</style>
