<template>
  <div class="contain">
    <div class="mainBody">
      <!-- <div class="formTit">
                <div class="formTit_en">BUSINESS FORM</div>
                <div class="formTit_cn">业务形态</div>
            </div> -->
      <div class="noticetit marginT40">业务形态</div>
      <ul class="busform marginT30 clear">
        <li v-for="(item, index) in resList" :key="index">
          <router-link
            :to="'/serviceArea?key=' + serviceAreaObj[item.businessName]"
            class="busform_item"
          >
            <swiperCpt
              ref="bannerSwiper1"
              :delay="3000"
              :autoplay="true"
              :sid="'bannerSwiper' + index"
            >
              <div
                class="swiper-slide"
                v-for="(imgItem, imgIndex) in item.imageUrl"
                :key="imgIndex"
              >
                <!-- <div class="busform_img">
                                <img :src="imgItem" :alt="'img0' + index" />
                            </div> -->
                <div class="busform_img">
                  <img
                    :src="$store.state.baseUrl + imgItem"
                    :alt="'img0' + index"
                  />
                </div>
              </div>
            </swiperCpt>
            <div class="busform_txt">{{ item.businessName }}</div>
            <div class="busform_con">
              <!-- <div class="fontsize18 colorfff">
                            {{ item.businessName }}
                        </div>
                        <div class="fontsize14 colorfff marginT10">
                            {{ item.title_en }}
                        </div>
                        <div
                            class="fontsize16 colorfff marginT20 busform_con_p"
                        >
                            {{ item.con }}
                        </div> -->
              <div
                class="fontsize16 colorfff busform_con_p"
                v-html="item.synopsis"
              ></div>
              <div class="busform_con_right">
                <img src="../../assets/images/index/line2.png" />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import swiperCpt from "@/components/common/swiperCpt.vue";
export default {
  components: {
    swiperCpt,
  },
  data() {
    return {
      // dataList: [
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form1.png"),
      //       require("../../assets/images/index/form2.png"),
      //       require("../../assets/images/index/businessForm1.png"),
      //     ],
      //     businessName: "城市综合体运维",
      //     title_en: "INDUSTRIAL PARK SERVICE",
      //     con:
      //       "连续18年为广交会展馆提供综合设备管理，具备城市综合体智能化、暖通、电气等各专业管理经验。政治素养高、经验丰富、技术突出、安全高效。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form2.png"),
      //       require("../../assets/images/index/businessForm2.png"),
      //     ],
      //     businessName: "城市综合管廊运维",
      //     title_en: "INDUSTRIAL PARK SERVICE",
      //     con:
      //       "具备17年管廊运维经验，先后承接广州、佛山、珠海等重点管廊项目。参与国家标准制定，主持编写地方标准，发起成立了中机维协管廊分会。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form3.png"),
      //       require("../../assets/images/index/businessForm3.png"),
      //     ],
      //     businessName: "工业园区服务",
      //     title_en: "INDUSTRIAL ESTATE SERVICE",
      //     con:
      //       "涵盖生产工序外包，生产性保障包括公共秩序维护、清洁、绿化、设施设备维保、客户服务、园区文化建设和业主委托的维修工程、员工食堂、员工交通车等专项服务。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form4.png"),
      //       require("../../assets/images/index/businessForm4-1.png"),
      //       require("../../assets/images/index/businessForm4-2.png"),
      //     ],
      //     businessName: "工厂运维服务",
      //     title_en: "INDUSTRIAL PARK SERVICE",
      //     con:
      //       "主要为华南地区著名企业广州JFE钢板有限公司等多家公司提供生产性服务，涵盖钢卷捆包，机电运维以及职工通勤车三大业务。",
      //   },
      //   {
      //     imageUrl: [require("../../assets/images/index/form5.png")],
      //     businessName: "产业园区服务",
      //     title_en: "INDUSTRIAL PARK SERVICE",
      //     con:
      //       "面向科技园、产业园、创意园等提供园区全生命周期的服务，除一般性的物业服务外，还包括下游的生活服务，如仓库、酒店、洗衣、商场、健身、娱乐等设施和服务。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form6.png"),
      //       require("../../assets/images/index/businessForm6.png"),
      //     ],
      //     businessName: "高端写字楼服务",
      //     title_en: "HIGH-END OFFICE BUILDING SERVICES",
      //     con:
      //       "以高端写字楼物业服务为基础，打造企业非核心业务外包服务链，包括公共秩序维护、清洁、绿化、设施设备维保、客户服务等，以及业主专项委托的水电维修等。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form7.png"),
      //       require("../../assets/images/index/businessForm7-1.png"),
      //       require("../../assets/images/index/businessForm7-2.png"),
      //     ],
      //     businessName: "在线商城",
      //     title_en: "ONLINE STORE",
      //     con: "提供优质、便捷、丰富的生活服务，为员工、客户提供多样性选择。",
      //   },
      //   {
      //     imageUrl: [
      //       require("../../assets/images/index/form8.png"),
      //       require("../../assets/images/index/businessForm8.png"),
      //     ],
      //     businessName: "生活服务",
      //     title_en: "LIFE SERVICES",
      //     con:
      //       "提供好生活超市服务，办公劳保用品、白凤山饮用水、金桑叶腊味、食用花生油和米面、双狮刀具及广州工控旗下品牌产品。",
      //   },
      // ],
      resList: [],
      serviceAreaObj: {
        城市综合体运维: "OperationAndMaintenance",
        城市综合管廊运维: "PipeGallery",
        工业园区服务: "IndustryParkService",
        工厂运维服务: "FactoryService",
        产业园区服务: "IndustrialParkService",
        高端写字楼服务: "OfficeServices",
        在线商城: "MallService",
        生活服务: "PrivateLabelService",
      },
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let res = await this.$request.getServiceTerritoryList();
      if (res.code === 200) {
        this.resList = res.rows;
        this.resList.map((item) => {
          item.imageUrl = item.imageUrl.split(",");
        });
        // this.resList.imgurl = imageUrl.split(",");
        // console.log("gkform", this.resList);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "../../assets/css/index.css";
.busform {
  margin-bottom: 1.1458vw;
}
.busform .busform_item {
  width: 100%;
  height: 100%;
  position: relative;
}
.busform > li {
  width: 20.1vw;
  float: left;
  margin-right: 0.9375vw;
  margin-bottom: 0.9375vw;
  position: relative;
}

.busform > li .busform_con {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3ca00;
  top: 0;
  left: 0;
  display: none;
  padding: 1.0417vw;
  box-sizing: border-box;
  z-index: 100;
}

.busform > li:hover .busform_con {
  display: block;
  transition: 0.3s;
}

.busform > li:nth-child(4n + 4) {
  margin-right: 0;
}

.busform > li .busform_img {
  width: 100%;
  height: 12.34vw;
}

.busform > li .busform_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.busform_txt {
  width: 100%;
  height: 2.3438vw;
  line-height: 2.3438vw;
  text-align: center;
  font-size: 0.8333vw;
  color: #333333;
  background: #eeeeee;
}

.busform_con_p {
  line-height: 1.25vw;
}

.busform_con_right {
  width: 1.875vw;
  position: absolute;
  right: 1.0417vw;
  bottom: 1.0417vw;
}

.busform_con_right > img {
  width: 100%;
}
@media (min-width: 1920px) {
  .busform {
    margin-bottom: 22px;
  }
  .busform .busform_item {
    width: 100%;
    height: 100%;
  }
  .busform > li {
    width: 386px;
    
    float: left;
    margin-right: 18px;
    margin-bottom: 18px;
    position: relative;
  }

  .busform > li .busform_con {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f3ca00;
    top: 0;
    left: 0;
    display: none;
    padding: 20px;
    box-sizing: border-box;
    z-index: 100;
  }

  .busform > li:hover .busform_con {
    display: block;
    transition: 0.3s;
  }

  .busform > li:nth-child(4n + 4) {
    margin-right: 0;
  }

  .busform > li .busform_img {
    width: 100%;
    height: 237px;
  }

  .busform > li .busform_img img {
    width: 100%;
    display: block;
  }

  .busform_txt {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    color: #333333;
    background: #eeeeee;
  }

  .busform_con_p {
    line-height: 30px;
  }

  .busform_con_right {
    width: 36px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .busform_con_right > img {
    width: 100%;
  }
}
</style>
