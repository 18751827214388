<template>
  <div class="contain clear">
    <!-- <div class="htxtT">文案待提供，文案待提供，文案待提供，文案待提供文案待提供，文案待提供</div> -->
    <div class="hconbg clear navRcon">
      <div class="fl honor_nav">
        <div
          :class="[
            honorbtn == 'honor' ? 'honorOn' : '',
            'honorbtn marginT20 textC cusorS fontsize16',
          ]"
          @click="honorbtn = 'honor'"
        >
          荣誉>
        </div>
        <div
          :class="[
            honorbtn == 'Qualification' ? 'honorOn' : '',
            'honorbtn marginT10 textC cusorS fontsize16',
          ]"
          @click="honorbtn = 'Qualification'"
        >
          资质>
        </div>
        <!-- <div class="honor marginT40 textC rightBtn" @click="nextPage()">
          <img src="../../assets/images/index/righton.png" />
        </div>
        <div class="marginT40 textC leftBtn" @click="perPage()">
          <img
            class=""
            style="transform: rotate(180deg);"
            src="../../assets/images/index/right.png"
          />
        </div> -->
      </div>
      <div class="fl honor_r">
        <div
          :class="[
            honorbtn == 'honor' ? 'on' : '',
            'honor_r_con honor_r_con01 ',
          ]"
        >
          <div class="textp fontsize16 color333">
            <span>荣誉奖项：</span>
            <span v-html="honorInfo">
              我司机电运维事业部连续多次获评全国设备管理优秀单位、广交会优秀外包单位；物业事业部在管的广州铜材厂有限公司被广东省建设厅评为“广东省物业管理示范工业区、广州市物业管理优秀工业区”。
            </span>
          </div>
          <swiperCpt
            class="swiperhor"
            ref="honorswiper"
            :slidesPerView="2"
            :autoplay="true"
            :loop="true"
            sid="honorswiper"
            v-if="honorImgList.length > 0"
          >
            <div
              class="swiper-slide"
              v-for="(item, index) in honorImgList"
              :key="'honor' + index"
            >
              <ul class="honor_img clear marginT20">
                <li>
                  <img
                    :src="$store.state.baseUrl + item.imageUrl"
                    :alt="'honor' + index"
                  />
                </li>
              </ul>
            </div>
          </swiperCpt>
        </div>
        <div
          :class="[
            honorbtn == 'Qualification' ? 'on' : '',
            'honor_r_con honor_r_con01 ',
          ]"
        >
          <div class="textp fontsize16 color333">
            <span>公司资质：</span>
            <span v-html="QualificationInfo">
              拥有设备维修安装、机电工程施工、电梯安装维修、物业服务等多项资质，并通过了ISO9001国际质量管理体系、ISO14001国际环境管理体系、OHSAS18001职业健康安全管理体系等认证。
            </span>
          </div>
          <swiperCpt
            class="swiperhor"
            ref="QualificationSwiper"
            :slidesPerView="4"
            :loop="true"
            :delay="3000"
            :autoplay="true"
            sid="QualificationSwiper"
            v-if="QualificationImgList.length > 0"
          >
            <div
              class="swiper-slide"
              v-for="(item, index) in QualificationImgList"
              :key="index"
            >
              <ul class="honor_img1 clear marginT20">
                <li>
                  <img
                    :src="$store.state.baseUrl + item.imageUrl"
                    :alt="'Qualification' + index"
                  />
                </li>
              </ul>
            </div>
          </swiperCpt>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swiperCpt from "@/components/common/swiperCpt.vue";
export default {
  components: { swiperCpt },
  data() {
    return {
      honorbtn: "honor", // honor Qualification
    //   honor_img: [
    //     require("../../assets/images/index/honor1.png"),
    //     require("../../assets/images/index/honor4.png"),
    //     require("../../assets/images/index/honor5.png"),
    //     require("../../assets/images/index/honor6.png"),
    //     require("../../assets/images/index/honor7.png"),
    //     require("../../assets/images/index/honor8.png"),
    //     require("../../assets/images/index/honor9.png"),
    //     require("../../assets/images/index/honor10.png"),
    //     require("../../assets/images/index/honor11.png"),
    //     require("../../assets/images/index/honor12.png"),
    //   ], // 荣耀图片,静态
    //   Qualification_img: [
    //     require("../../assets/images/index/honorzz1.png"),
    //     require("../../assets/images/index/honorzz2.png"),
    //     require("../../assets/images/index/honorzz3.png"),
    //     require("../../assets/images/index/honorzz4.png"),
    //     require("../../assets/images/index/honorzz5.png"),
    //     require("../../assets/images/index/honorzz6.png"),
    //     require("../../assets/images/index/honorzz7.png"),
    //     require("../../assets/images/index/honorzz8.png"),
    //   ], // 资质图片,静态
      honorInfo: "",
      QualificationInfo: "",
      honorImgList: [], // 荣耀图片
      QualificationImgList: [], // 资质图片
    };
  },
  created(){
    this.getEnterprisePage();
    this.getHonorList();
    this.getQualificationList();
  },
  mounted() {
   
  },
  methods: {
    nextPage() {
      this.$refs.honorswiper.mySwiper.slideNext();
    },
    perPage() {
      this.$refs.honorswiper.mySwiper.slidePrev();
    },
    // 获取企业信息
    async getEnterprisePage() {
      let res = await this.$request.getEnterpriseById({
        enterpriseId: "e230b713-95c4-40c0-89de-f73d645e25a0",
      });
      if (res.code === 200) {
        // console.log(res.data);
        this.honorInfo = res.data.honor;
        this.QualificationInfo = res.data.qualification;
        // dataList = res
      }
      // console.log(dataList[0]);
    },
    // 获取荣耀图片列表
    async getHonorList() {
      let resHonorData = await this.$request.getImageManageList({
        pageNo: 1,
        pageSize: 10,
        columnType: 51,
      });
      if (resHonorData.code === 200) {
        // console.log("resHonorData", resHonorData);
        this.honorImgList = resHonorData.rows;
      }
    },
    // 获取资质图片列表
    async getQualificationList() {
      let resQualificationData = await this.$request.getImageManageList({
        pageNo: 1,
        pageSize: 10,
        columnType: 52,
      });
      if (resQualificationData.code === 200) {
        // console.log("resQualificationData", resQualificationData);
        this.QualificationImgList = resQualificationData.rows;
      }
    },
    // 获取栏目列表
    async getDataList() {
      let res = await this.$request.getImageManageColumnList();
      if (res.code === 200) {
        // console.log("res", res);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "../../assets/css/index.css";

.hconbg {
  border: solid 0.0521vw #e0e0e0;
}

.hconbg .honorbtn {
  width: 4.6875vw;
  height: 2.2396vw;
  line-height: 2.2396vw;
  background-color: #eeeeee;
  columns: #333333;
}

.hconbg .honorbtn.honorOn {
  background-color: #f3ca00;
}

.hconbg .honor_r {
  width: 39.0625vw;
  padding: 1.5625vw;
}

.hconbg .honor_r .textp {
  line-height: 1.5625vw;
}

.hconbg .honor_r .honor_img li {
  width: 17.45vw;
  height: 11.92vw;
  float: left;
  margin-right: 1.04vw;
}

.hconbg .honor_r .honor_img li img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hconbg .honor_r .honor_img li:last-child {
  margin-right: 0;
}

.hconbg .honor_r .honor_img1 li {
  width: 9.1146vw;
  float: left;
  margin-right: 0.5208vw;
}

.hconbg .honor_r .honor_img1 li img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hconbg .honor_r .honor_img1 li:last-child {
  margin-right: 0;
}

.honor_r .honor_r_con {
  opacity: 0;
  height: 0;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.honor_r .honor_r_con.on {
  opacity: 1;
  height: auto;
  overflow: auto;
  z-index: 9;
}

.htxtT {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}
@media (min-width: 1920px) {
  .hconbg {
    border: solid 1px #e0e0e0;
  }

  .hconbg .honorbtn {
    width: 90px;
    height: 43px;
    line-height: 43px;
    background-color: #eeeeee;
    columns: #333333;
  }

  .hconbg .honorbtn.honorOn {
    background-color: #f3ca00;
  }

  .hconbg .honor_r {
    width: 750px;
    padding: 30px;
  }

  .hconbg .honor_r .textp {
    line-height: 30px;
  }

  .hconbg .honor_r .honor_img li {
    width: 335px;
    height: 228.84px;
    float: left;
    margin-right: 20px;
  }

  .hconbg .honor_r .honor_img li img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hconbg .honor_r .honor_img li:last-child {
    margin-right: 0;
  }

  .hconbg .honor_r .honor_img1 li {
    width: 175px;
    float: left;
    margin-right: 10px;
  }

  .hconbg .honor_r .honor_img1 li img {
    width: 100%;
  }

  .hconbg .honor_r .honor_img1 li:last-child {
    margin-right: 0;
  }

  .honor_r .swiperhor .honor_r_con {
    opacity: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }

  .honor_r .swiperhor .honor_r_con.on {
    opacity: 1;
    height: auto;
    overflow: auto;
  }
}
</style>
